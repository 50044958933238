/* ==========================================================================
#
# ///////////////////////////// SG GLOBAL ///////////////////////////////////
#
========================================================================== */

/*-------------------------------------------
		Table of Contents:
		
		// General
		1. Flash of Unstyled Content
		2. Lines Button
		3. Equal Height
    4. Quick Links
    5. Youtube Lazyload


-------------------------------------------*/


// Foundation
$(document).foundation();

/* ==========================================================================
#. GENERAL SCRIPTS////////////////////////////////////////////////////////
========================================================================== */
/* ==========================================================================
	1. Flash of Unstyled Content 
	========================================================================== */
	/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
		.body-content-wrapper should be a wrapper that contains everything 
		except the header...
	+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
	$(document).ready(function(){
		$('.body-content-wrapper').addClass('initialized');
	});


/* ==========================================================================
	2. Lines Button -- Tablet/Mobile Navigation Hamburger
	========================================================================== */

	$("a.lines-button").on('click', function(){
		lineButton = $(this);
		var offCanvasWrapper = lineButton.parents('.off-canvas-wrapper').find('.off-canvas');

		if(!offCanvasWrapper.hasClass('is-open')){
				lineButton.addClass('close');
		}else{
				lineButton.removeClass('close');
		}

		$('.js-off-canvas-overlay').on('click', function(){
			if(!$(this).hasClass('is-visible')){
					lineButton.removeClass('close');
			}else{
					lineButton.addClass('close');
			}
		});
	});



/* ==========================================================================
	3. Equal Height
	========================================================================== */

	equalheight = function(container){

	var currentTallest = 0,
			 currentRowStart = 0,
			 rowDivs = new Array(),
			 $el,
			 topPosition = 0;
	 	$(container).each(function() {

		 	$el = $(this);
		 	$($el).height('auto')
		 	topPostion = $el.position().top;

		 	if (currentRowStart != topPostion) {
			 	for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				 	rowDivs[currentDiv].height(currentTallest);
				}
				rowDivs.length = 0; // empty the array
				currentRowStart = topPostion;
				currentTallest = $el.height();
				rowDivs.push($el);
		 	} else {
			 	rowDivs.push($el);
			 	currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
			}
		 	for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
			 	rowDivs[currentDiv].height(currentTallest);
		 	}
 		});
	}

	$(window).load(function() {
		equalheight('*[data-equalize]');
	});


	$(window).resize(function(){
		equalheight('*[data-equalize]');
	});



/* ==========================================================================
	4. Quick Link
	========================================================================== */

	$(function() {
        $('a.quick-link[href*=#]:not([href=#])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 150
                    }, 1000);
                    return false;
                }
            }
        });
    });


/* ==========================================================================
	#. CUSTOM ////////////////////////////////////////////////////////
	========================================================================== */
	/* ==========================================================================
		2.  Image Replacement
		========================================================================== */
	    // Images
	    window.onload = function() {
				
			 // 1.1: If Background Image Exists 
			if(document.querySelectorAll('.background-image').length){
				const backgroundImage = document.querySelectorAll('.background-image');

			    // 1--1: Loop through and update SRC
			    Array.from(backgroundImage).forEach(background => {
			       	var imgLarge = background.dataset.large;
			       	if(background.hasAttribute('data-large')){
			       		background.style.backgroundImage = `url(${imgLarge})`;
			        	background.classList.add('loaded');
			       	}
			    }); 
			}

			 // 2.1: If Img Placholder Exists 
			if(document.querySelectorAll('.img-placeholder').length){
				const placeholder = document.querySelectorAll('.img-placeholder');
	  		
		  		// 2--1: Loop through and update SRC  
		    	Array.from(placeholder).forEach(ImagePlaceholder => {
		    		let imgSmall = ImagePlaceholder.querySelector('.img-small');
		    		let imgLarge = ImagePlaceholder.dataset.large; 
		    			
		    		imgSmall.src = imgLarge;
		    		imgSmall.classList.add('loaded');
		    		imgSmall.classList.remove('img-small');

				});
			}
		}


	/* ==========================================================================
		3.  Foundation Load -- Sticky & Offcanvas
		========================================================================== */

		// Sticky
		// $('.header-main-small').on('sticky.zf.stuckto:top', function(){
		//   	$(this).addClass('shrink');
		// }).on('sticky.zf.unstuckfrom:top', function(){
		//   	$(this).removeClass('shrink');
		// })

		// // Offcanvas
		// $(window).scroll(function(){
	  //   	if($('.off-canvas').hasClass('is-open')){
	  //   		$('.off-canvas').foundation('close');
	  //   		$('.lines-button').removeClass('close');
	  //   	}
	  //   });

  
	/* ==========================================================================
		4.  Youtube Lazy Load
		========================================================================== */    

	    document.addEventListener("DOMContentLoaded",
	        function() {
	            var div, n,
	                v = document.getElementsByClassName("youtube-player");
	            for (n = 0; n < v.length; n++) {
	                div = document.createElement("div");
	                div.setAttribute("data-id", v[n].dataset.id);
	                div.innerHTML = labnolThumb(v[n].dataset.id);
	                div.onclick = labnolIframe;
	                v[n].appendChild(div);
	            }
	        });

	    function labnolThumb(id) {
	        var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg">',
	            play = '<div class="play"></div>';
	        return thumb.replace("ID", id) + play;
	    }

	    function labnolIframe() {
	        var iframe = document.createElement("iframe");
	        var embed = "https://www.youtube.com/embed/ID?autoplay=1";
	        iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
	        iframe.setAttribute("frameborder", "0");
	        iframe.setAttribute("allowfullscreen", "1");
	        this.parentNode.replaceChild(iframe, this);
	    }	$('.header-main-small').on('sticky.zf.stuckto:top', function(){
		  	$(this).addClass('shrink');
		}).on('sticky.zf.unstuckfrom:top', function(){
		  	$(this).removeClass('shrink');
		})